<template>
  <div id="bank-create-details-tab">
    <div class="vx-col w-full">
      <div class="vx-row">
        <div class="vx-col lg:w-1/2 sm:w-1/1 w-full mb-2">
          <vs-input class="w-full" label-placeholder="Bank Name" v-model="dataLocal.bank_name" @change="bankUpdateData" />
        </div>
        <div class="vx-col lg:w-1/2 sm:w-1/2 w-full mb-2">
          <vs-select class="w-full mt-5" placeholder="Bank Status" v-model="dataLocal.status" @change="bankUpdateData">
            <vs-select-item :key="index" :value="option" :text="option | capitalize" v-for="(option, index) in statusOptions" />
          </vs-select>
        </div>
      </div>
      <div class="vx-row mb-4">
        <div class="vx-col w-full">
          <vs-input class="w-full" label-placeholder="Thumbnail URL" v-model="dataLocal.bank_thumbnail" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full mt-2">
          <vs-textarea
            label="Bank Description"
            counter="400"
            :counter-danger.sync="bank_description_counter"
            height="100px"
            v-model="dataLocal.bank_description"
            @change="bankUpdateData"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button color="success" type="filled" class="mb-2 mr-2" @click="submitData">Create Bank </vs-button>
          <vs-button color="warning" type="border" class="mb-2 ml-2" @click="resetData">Reset </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      retailer_options: [],
      bank_description_counter: null,

      statusOptions: ['active', 'hidden', 'disabled'],
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    bankUpdateData() {
      this.$emit('update_bankData', this.dataLocal);
    },
    submitData() {
      this.$emit('insert_bankData', this.dataLocal);
    },
    resetData() {
      this.dataLocal = { bank_name: undefined, bank_description: undefined, bank_status: undefined, bank_code: undefined };
      this.bankUpdateData();
    },
  },
};
</script>

<style scoped></style>
