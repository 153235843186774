<!-- ================:=========================================================================
  File Name: EditBank.vue
  Description: User Edit Page

========================================================================================== -->

<template>
  <div id="bank-new">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Details" icon-pack="feather" icon="icon-edit">
            <div class="tab-text">
              <bank-details-tab :data="bankData" v-on:update_bankData="bankDataUpdate" v-on:insert_bankData="createBank" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import BankDetailsTab from './BankDetailsTab.vue';

export default {
  components: {
    BankDetailsTab,
  },
  data() {
    return {
      bankData: {
        bank_name: undefined,
        bank_description: undefined,
        bank_status: undefined,
        bank_thumbnail: undefined,
      },

      module_data: null,

      activeTab: 0,
    };
  },
  watch: {
    activeTab() {
      //tab change
    },
  },
  methods: {
    bankDataUpdate(data) {
      this.bankData = data;
    },
    createBank() {
      const pl = {
        name: this.bankData.bank_name,
        description: this.bankData.bank_description,
        thumbnail: this.bankData.bank_thumbnail,
        status: this.bankData.bank_status === undefined ? 'active' : this.bankData.bank_status,
      };

      if (pl.name === undefined) {
        return this.$vs.notify({
          title: 'Failed to create bank',
          text: 'Please provide a Bank Name',
          color: 'danger',
          position: 'top-right',
        });
      }

      this.$vs.loading();

      this.$http
        .post('examedge/bank', pl)
        .then((response) => {
          this.$vs.loading.close();

          this.$vs.notify({
            title: 'Created bank successfully',
            text: `New local bank ${pl.name} has been created.`,
            color: 'success',
            position: 'top-right',
          });

          if (response.status === 200) {
            const data = response.data;
            this.$router.push(`/a/examedge/banks/${data.id}`);
          }
        })
        .catch((exception) => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while creating this bank';
          if (exception.response) {
            error = exception.response.data.error.description;
          }

          return this.$vs.notify({
            title: 'Failed to create bank',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
  },
};
</script>
